import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading.js";

const LoadableComponent = loadable(() => import("./SecurityImage"), {
  fallback: <Loading />
});

const SecurityImageLoadable = () => (
  <LoadableComponent />
)

export default SecurityImageLoadable;
