import '../../index.css'
import React, { useEffect, useState } from 'react'
import PersonalBeneficiary from './Component/PersonalBeneficiary'
import BusinessBeneficiary from './Component/BusinessBeneficiary'
import { Grid } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { GET_BENEFICIARY_FORM } from '../../Assets/Constant/BeneficiaryForm'
import ToggleButtonGroup from '../../Component/ToggleButtonGroup'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined'

const useStyles = makeStyles({
  wrapper: {
    textAlign: 'center',
  },
  header: {
    fontFamily: "'Lato', sans-serif",
    fontSize: 28,
    backgroundColor: '#212529',
    color: 'white',
    display: 'inline-block',
    paddingTop: 40,
    paddingBottom: 40,
    marginBottom: 15,
    width: '100%',
  },
  border: {
    marginTop: 16,
    marginBottom: 16,
  },
  sectionHeader: {
    fontWeight: 700,
    fontFamily: "'Lato', sans-serif",
    fontSize: 24,
    marginBottom: 16,
  },
  saveButton: {
    fontSize: 20,
    textTransform: 'capitalize',
    color: 'white',
  },
  toggleButton: {
    backgroundColor: 'white',
  },
  buttonDescription: {
    marginTop: '8px',
    fontSize: '14px',
  },
})

const AddNewBeneficiary = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const beneficiaryForm = useSelector((state) => state.beneficiaryForm)
  const [showPersonal, setShowPersonal] = React.useState(true)
  const [showBusiness, setShowBusiness] = React.useState(false)
  const [value, setValue] = React.useState('PERSONAL')

  useEffect(() => {
    if (beneficiaryForm.entityType.length === 0) {
      dispatch({
        type: GET_BENEFICIARY_FORM,
      })
    }
  }, [])

  const handleToggleClick = (item) => {
    setValue(item)
  }

  const renderToggleButton = () => {
    let entityType = null
    if (beneficiaryForm.entityType.length !== 0) {
      entityType = beneficiaryForm.entityType.map((index) => {
        return (
          <ToggleButton
            key={index}
            classes={{ root: classes.toggleButton }}
            onClick={() => handleToggleClick(index)}
            value={index}
            aria-label='personal account'
          >
            <span
              style={{
                textTransform: 'capitalize',
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              {index}
            </span>
          </ToggleButton>
        )
      })
    }

    return entityType
  }

  const changeView = (entityType) => {
    setShowBusiness(!showBusiness)
    setShowPersonal(!showPersonal)
    setValue(entityType)
  }

  return (
    <div style={{ alignItems: 'center' }}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <span
            style={{ fontWeight: 'bold', fontFamily: "'Lato', sans-serif" }}
          >
            Add New Recipient
          </span>
        </div>
      </div>
      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item md={8} xs={12}>
            <p className={classes.sectionHeader}>
              Choose Recipient Account Type
            </p>
            <hr className={classes.border} />
            <p>Who are you sending to?</p>
            <ToggleButtonGroup
              style={{ marginBottom: '10px' }}
              changeView={changeView}
              defaultValue='PERSONAL'
            >
              <ToggleButtonGroup.Button
                value='PERSONAL'
                title='Personal account'
                icon={<PersonOutlineOutlinedIcon />}
              >
                <p className={classes.buttonDescription}>
                  I wish to make transfers to an individual recipient account
                </p>
              </ToggleButtonGroup.Button>
              <ToggleButtonGroup.Button
                value='BUSINESS'
                title='Business account'
                icon={<BusinessCenterOutlinedIcon />}
              >
                <p className={classes.buttonDescription}>
                  I wish to make transfers to a business recipient account
                </p>
              </ToggleButtonGroup.Button>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        {value && value === 'PERSONAL' ? (
          <PersonalBeneficiary entityType={value} />
        ) : (
          <BusinessBeneficiary entityType={value} />
        )}
      </div>
    </div>
  )
}

export default AddNewBeneficiary
