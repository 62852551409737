export const parseJwt = (jwt) => {
  if (jwt.length < 1) {
    return null;
  }
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  return JSON.parse(jsonPayload);
};

export const isTokenExpired = (token) => token?.exp * 1000 <= new Date().valueOf();
