import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading.js";

const LoadableComponent = loadable(() => import("./EditMissingSenderInfo"), {
  fallback: <Loading />
});

const LoadableEditMissingSenderInfo = () => (
  <LoadableComponent />
)

export default LoadableEditMissingSenderInfo;
