import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '8vh',
    boxShadow: '0 1px 12px -5px gray'
  },
  container: {
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: 'center'
  },
  grid: {
    height: '8vh'
  },
  logo: {
    cursor: 'pointer',
    minWidth: '10vw'
  },
  menu: {
    flexDirection: 'row'
  },
  link: {
    marginRight: 15,
    textDecoration: 'none',
    color: 'black'
  },
  buttonLogin: {
    borderRadius: 0,
    padding: '12px 50px',
    boxShadow: '0 0 0 0',
    marginLeft: 10,
  },
  buttonRegister: {
    borderRadius: 0,
    padding: '12px 25px',
    marginLeft: 10,
  }
}))

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Container>
        <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.grid}
        >
          <div>
            <img src={require('../Assets/Images/MMlogo1.svg')} alt="moneymatch-logo" className={classes.logo} onClick={()=>{
              window.history.back();
            }}/>
          </div>
          <div className={classes.menu}>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default Header;
