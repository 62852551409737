import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading.js";

const LoadableComponent = loadable(() => import("./UserBank"), {
  fallback: <Loading />
});

const LoadableUserBank = () => (
  <LoadableComponent />
)

export default LoadableUserBank;
