import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading.js";

const LoadableComponent = loadable(() => import("./AddRemitFXTransfer"), {
  fallback: <Loading />
});

const LoadableRemitFXTransfer = () => (
  <LoadableComponent />
)

export default LoadableRemitFXTransfer;
