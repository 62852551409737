import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading.js";

const LoadableComponent = loadable(() => import("./UserBankEdit"), {
  fallback: <Loading />
});

const LoadableUserBankEdit = () => (
  <LoadableComponent />
)

export default LoadableUserBankEdit;
