export const GET_REMITFX_TRANSFER_FORM = 'GET_REMITFX_TRANSFER_FORM';
export const GET_REMITFX_TRANSFER_FORM_SUCCESS = 'GET_REMITFX_TRANSFER_FORM_SUCCESS';
export const GET_REMITFX_TRANSFER_FORM_FAIL = 'GET_REMITFX_TRANSFER_FORM_FAIL';

export const VERIFY_REMITFX_FORM = 'VERIFY_REMITFX_FORM';
export const VERIFY_REMITFX_FORM_SUCCESS = 'VERIFY_REMITFX_FORM_SUCCESS';
export const VERIFY_REMITFX_FORM_FAIL = 'VERIFY_REMITFX_FORM_FAIL';

export const SUBMIT_REMITFX_FORM = 'SUBMIT_REMITFX_FORM';
export const SUBMIT_REMITFX_FORM_SUCCESS = 'SUBMIT_REMITFX_FORM_SUCCESS';
export const SUBMIT_REMITFX_FORM_FAIL = 'SUBMIT_REMITFX_FORM_FAIL';

export const UPDATE_REMITFX_FILLED_DATA = 'UPDATE_REMITFX_FILLED_DATA';
export const RESET_REMITFX_FILLED_DATA = 'RESET_REMITFX_FILLED_DATA';

export const REMOVE_REMITFX_FORM_ERROR = 'REMOVE_REMITFX_FORM_ERROR';
