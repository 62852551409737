import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_FLEX_FORM, GET_FLEX_FORM_SUCCESS,
  VERIFY_FLEX_FORM, VERIFY_FLEX_FORM_FAIL, VERIFY_FLEX_FORM_SUCCESS,
  SUBMIT_FLEX_FORM, SUBMIT_FLEX_FORM_SUCCESS, SUBMIT_FLEX_FORM_FAIL,
  GET_EDIT_COMPLETE_PROFILE_FORM,
  SUBMIT_EDIT_COMPLETE_PROFILE_FORM,
  SUBMIT_EDIT_COMPLETE_PROFILE_FORM_SUCCESS,
  SUBMIT_EDIT_COMPLETE_PROFILE_FORM_FAIL, GET_FLEX_FORM_FAIL,
} from "../Assets/Constant/CompleteProfileForm";

import { getBaseUrl, COMPLIANCE } from '../Network/baseUrl';
import http from '../Network/http';

function* getFlexForm() {
    const region = localStorage.getItem('region');

  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/entities/profile/create',
      params: {
        region
      }
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    yield put({
      type: GET_FLEX_FORM_SUCCESS,
      data,
    });
  } catch (e) {
    console.log(e);
  }
}

function* verifyInput(item) {
  try {
    const region = localStorage.getItem('region');
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/entities/verify-input',
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
        region
      })
    };
    const res = yield call(http, apiConfig);
    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: VERIFY_FLEX_FORM_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: VERIFY_FLEX_FORM_FAIL,
        data,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* submitForm(item) {
  try {
    const region = localStorage.getItem('region');
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/entities',
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
        region
      })
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    // console.log('saga: ', errors)
    if (res.status === 200) {
      yield put({
        type: SUBMIT_FLEX_FORM_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: SUBMIT_FLEX_FORM_FAIL,
        data,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* getEditCompleteProfileForm(payload) {
  const { id, navigate } = payload;
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/entities/${id}/edit`,
      params: {
        region,
      },
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    if (res.status === 200) {
      yield put({
        type: GET_FLEX_FORM_SUCCESS,
        data,
      });
      const entityType = data.fields.find((item) => item.name === 'entity_type').value;
      if (navigate) navigate(entityType);
    }
  } catch (e) {
    yield put({
      type: GET_FLEX_FORM_FAIL,
      error: e,
    });
  }
}

function* submitEditCompleteProfileForm(payload) {
  const { id, navigate, entity_type } = payload;
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/entities/${id}/update`,
      params: {
        region,
      },
      data: JSON.stringify({
        ...payload.data,
        entity_type,
      }),
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_EDIT_COMPLETE_PROFILE_FORM_SUCCESS,
        data,
      });
      if (navigate) navigate();
    } else {
      yield put({
        type: SUBMIT_EDIT_COMPLETE_PROFILE_FORM_FAIL,
        data,
        errors,
      });
    }
  } catch (e) {
    console.log(e)
  }
}

export function* watchFlexForm() {
  yield takeLatest(GET_FLEX_FORM, getFlexForm);
  yield takeLatest(GET_EDIT_COMPLETE_PROFILE_FORM, getEditCompleteProfileForm);
  yield takeLatest(VERIFY_FLEX_FORM, verifyInput);
  yield takeLatest(SUBMIT_FLEX_FORM, submitForm);
  yield takeLatest(SUBMIT_EDIT_COMPLETE_PROFILE_FORM, submitEditCompleteProfileForm);
}
