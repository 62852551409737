export const GET_FLEX_FORM = 'GET_FLEX_FORM';
export const GET_FLEX_FORM_SUCCESS = 'GET_FLEX_FORM_SUCCESS';
export const GET_FLEX_FORM_FAIL = 'GET_FLEX_FORM_FAIL';

export const VERIFY_FLEX_FORM = 'VERIFY_FLEX_FORM';
export const VERIFY_FLEX_FORM_SUCCESS = 'VERIFY_FLEX_FORM_SUCCESS';
export const VERIFY_FLEX_FORM_FAIL = 'VERIFY_FLEX_FORM_FAIL';

export const SUBMIT_FLEX_FORM = 'SUBMIT_FLEX_FORM';
export const SUBMIT_FLEX_FORM_SUCCESS = 'SUBMIT_FLEX_FORM_SUCCESS';
export const SUBMIT_FLEX_FORM_FAIL = 'SUBMIT_FLEX_FORM_FAIL';

export const UPDATE_FILLED_DATA = 'UPDATE_FILLED_DATA';
export const RESET_FILLED_DATA = 'RESET_FILLED_DATA';

export const GET_EDIT_COMPLETE_PROFILE_FORM = 'GET_EDIT_COMPLETE_PROFILE_FORM';
export const GET_EDIT_COMPLETE_PROFILE_FORM_SUCCESS = 'GET_EDIT_COMPLETE_PROFILE_FORM_SUCCESS';
export const GET_EDIT_COMPLETE_PROFILE_FORM_FAIL = 'GET_EDIT_COMPLETE_PROFILE_FORM_FAIL';

export const SUBMIT_EDIT_COMPLETE_PROFILE_FORM = 'SUBMIT_EDIT_COMPLETE_PROFILE_FORM';
export const SUBMIT_EDIT_COMPLETE_PROFILE_FORM_SUCCESS = 'SUBMIT_EDIT_COMPLETE_PROFILE_FORM_SUCCESS';
export const SUBMIT_EDIT_COMPLETE_PROFILE_FORM_FAIL = 'SUBMIT_EDIT_COMPLETE_PROFILE_FORM_FAIL';


export const REQUEST_TAC = 'REQUEST_TAC';
export const REQUEST_TAC_SUCCESS = 'REQUEST_TAC_SUCCESS';
export const REQUEST_TAC_FAIL = 'REQUEST_TAC_FAIL';

export const REMOVE_FLEX_FORM_ERROR = 'REMOVE_FLEX_FORM_ERROR';
