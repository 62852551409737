import { combineReducers } from '@reduxjs/toolkit';
import counterReducer from '../Reducers/counterSlice';
import flexFormReducer from "./completeProfileForm";
import beneficiaryFormReducer from "./beneficiaryForm";
import bankFormReducer from "./bankForm";
import missingSenderInfo from "./missingSenderInfoForm";
import ekycDocumentsStatus from "./ekycDocumentsStatus";
import remitfxFormReducer from "./remitFXTransferForm";

const rootReducer = combineReducers({
  counterReducer,
  flexForm: flexFormReducer,
  beneficiaryForm: beneficiaryFormReducer,
  bankForm: bankFormReducer,
  missingSenderInfoForm: missingSenderInfo,
  ekycDocumentsStatus,
  remitFXTransferForm: remitfxFormReducer
});

export default rootReducer;

