import React from 'react';
import {Grid, LinearProgress} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
  },
  linear: {
    width: '20vw',
  }
}))

const Loading = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center" className={classes.main}>
      <LinearProgress className={classes.linear}/>
    </Grid>
  )
}

export default Loading;
