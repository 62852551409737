import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_REMITFX_TRANSFER_FORM,
  GET_REMITFX_TRANSFER_FORM_SUCCESS,
  GET_REMITFX_TRANSFER_FORM_FAIL,
  VERIFY_REMITFX_FORM,
  VERIFY_REMITFX_FORM_SUCCESS,
  VERIFY_REMITFX_FORM_FAIL,
  SUBMIT_REMITFX_FORM,
  SUBMIT_REMITFX_FORM_SUCCESS,
  SUBMIT_REMITFX_FORM_FAIL
} from "../Assets/Constant/RemitFXTransferForm";

import { getBaseUrl, REMITFX } from '../Network/baseUrl';
import http from '../Network/httpRemitFX';

function* getRemitFXTransferForm() {
  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(REMITFX),
      url: '/api/transfers-flex-form/create'
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    yield put({
      type: GET_REMITFX_TRANSFER_FORM_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: GET_REMITFX_TRANSFER_FORM_FAIL,
      error: e,
    });
  }
}

function* verifyBeneficiaryInput(item) {
  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(REMITFX),
      url: '/api/transfers-flex-form/verify-input',
      data: JSON.stringify({
        ...item.data,
      })
    };
    const res = yield call(http, apiConfig);
    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: VERIFY_REMITFX_FORM_SUCCESS,
        data,
        target: item.data.__target
      });
    } else {
      yield put({
        type: VERIFY_REMITFX_FORM_FAIL,
        data,
        target: item.data.__target,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* submitBeneficiaryForm(item) {
  try {
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(REMITFX),
      url: '/api/transfers-flex-form',
      data: JSON.stringify({
        ...item.data,
      })
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_REMITFX_FORM_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: SUBMIT_REMITFX_FORM_FAIL,
        data,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

export function* watchRemitFXTransferForm() {
  yield takeLatest(GET_REMITFX_TRANSFER_FORM, getRemitFXTransferForm);
  yield takeLatest(VERIFY_REMITFX_FORM, verifyBeneficiaryInput);
  yield takeLatest(SUBMIT_REMITFX_FORM, submitBeneficiaryForm);
}