export const GET_BENEFICIARY_FORM = 'GET_BENEFICIARY_FORM';
export const GET_BENEFICIARY_FORM_SUCCESS = 'GET_BENEFICIARY_FORM_SUCCESS';
export const GET_BENEFICIARY_FORM_FAIL = 'GET_BENEFICIARY_FORM_FAIL';

export const RESET_BENEFICIARY_FORM = 'RESET_BENEFICIARY_FORM';

export const VERIFY_BENEFICIARY_FORM = 'VERIFY_BENEFICIARY_FORM';
export const VERIFY_BENEFICIARY_FORM_SUCCESS = 'VERIFY_BENEFICIARY_FORM_SUCCESS';
export const VERIFY_BENEFICIARY_FORM_FAIL = 'VERIFY_BENEFICIARY_FORM_FAIL';

export const REPLACE_ACTIVE_ADDITIONAL_FORM = 'REPLACE_ACTIVE_ADDITIONAL_FORM';
export const REPLACE_ACTIVE_ADDITIONAL_FORM_SUCCESS = 'REPLACE_ACTIVE_ADDITIONAL_FORM_SUCCESS';
export const REPLACE_ACTIVE_ADDITIONAL_FORM_FAIL = 'REPLACE_ACTIVE_ADDITIONAL_FORM_FAIL';

export const SUBMIT_BENEFICIARY_FORM = 'SUBMIT_BENEFICIARY_FORM';
export const SUBMIT_BENEFICIARY_FORM_SUCCESS = 'SUBMIT_BENEFICIARY_FORM_SUCCESS';
export const SUBMIT_BENEFICIARY_FORM_FAIL = 'SUBMIT_BENEFICIARY_FORM_FAIL';

export const SUBMIT_BENEFICIARY_BANK_FORM = 'SUBMIT_BENEFICIARY_BANK_FORM';
export const SUBMIT_BENEFICIARY_BANK_FORM_SUCCESS = 'SUBMIT_BENEFICIARY_BANK_FORM_SUCCESS';
export const SUBMIT_BENEFICIARY_BANK_FORM_FAIL = 'SUBMIT_BENEFICIARY_BANK_FORM_FAIL';

export const REMOVE_BENEFICIARY_FORM_ERROR = 'REMOVE_BENEFICIARY_FORM_ERROR';

export const GET_EDIT_BENEFICIARY_FORM = 'GET_EDIT_BENEFICIARY_FORM';
export const GET_EDIT_BENEFICIARY_FORM_SUCCESS = 'GET_EDIT_BENEFICIARY_FORM_SUCCESS';
export const GET_EDIT_BENEFICIARY_FORM_FAIL = 'GET_EDIT_BENEFICIARY_FORM_FAIL';

export const SUBMIT_EDIT_BENEFICIARY_FORM = 'SUBMIT_EDIT_BENEFICIARY_FORM';
export const SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS = 'SUBMIT_EDIT_BENEFICIARY_FORM_SUCCESS';
export const SUBMIT_EDIT_BENEFICIARY_FORM_FAIL = 'SUBMIT_EDIT_BENEFICIARY_FORM_FAIL';

export const GET_EDIT_MISSING_BENEFICIARY_FORM = 'GET_EDIT_MISSING_BENEFICIARY_FORM';
export const GET_EDIT_MISSING_BENEFICIARY_FORM_SUCCESS = 'GET_EDIT_MISSING_BENEFICIARY_FORM_SUCCESS';
export const GET_EDIT_MISSING_BENEFICIARY_FORM_FAIL = 'GET_EDIT_MISSING_BENEFICIARY_FORM_FAIL';

export const SUBMIT_EDIT_MISSING_BENEFICIARY_FORM = 'SUBMIT_EDIT_MISSING_BENEFICIARY_FORM';
export const SUBMIT_EDIT_MISSING_BENEFICIARY_FORM_SUCCESS = 'SUBMIT_MISSING_EDIT_BENEFICIARY_FORM_SUCCESS';
export const SUBMIT_EDIT_MISSING_BENEFICIARY_FORM_FAIL = 'SUBMIT_EDIT_MISSING_BENEFICIARY_FORM_FAIL';

export const UPDATE_BENEFICIARY_FILLED_DATA = 'UPDATE_BENEFICIARY_FILLED_DATA';
export const RESET_BENEFICIARY_FILLED_DATA = 'RESET_BENEFICIARY_FILLED_DATA';

export const REQUEST_TAC = 'REQUEST_TAC';
export const REQUEST_TAC_SUCCESS = 'REQUEST_TAC_SUCCESS';
export const REQUEST_TAC_FAIL = 'REQUEST_TAC_FAIL';
