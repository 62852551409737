import {ACCOUNT, getBaseUrl} from "../baseUrl";
import http from "../http";
import {parseJwt, isTokenExpired} from './JWTUtil';

const decodeToken = (jwt) =>
    typeof jwt === "string" ? parseJwt(jwt) : null;

class RefreshTokenProvider {
    static instance = RefreshTokenProvider.instance || new RefreshTokenProvider()

    _refreshLeeway = 60;
    _refreshTimer = undefined;
    //This method checks if token refresh is needed, performs refresh
    //and calls itself again in a second

    stop(){
        clearTimeout(this._refreshTimer);
    }
    start(){
        this._maybeRefresh();
    }
    async _maybeRefresh() {

        const jwt = await localStorage.getItem('token');
        clearTimeout(this._refreshTimer)
        let decodedToken = null;
        try{
            decodedToken = decodeToken(jwt);
        }catch (e){
            return;
        }
        try {
            if (decodedToken === null) {
                //no token - no need to refresh
                return;
            }

            if (
                decodedToken.exp * 1000 - new Date().valueOf() >
                this._refreshLeeway * 1000
            ) {
                //Refresh is not needed yet because token will not expire soon
                return;
            }

            if (isTokenExpired(decodedToken)) {
                //Somehow we have a token that is already expired
                //Possible when user loads app after long absence

                return;
                // throw new Error("Token is expired");
            }

            //If we are not returned from try block earlier, it means
            //we need to refresh token
            //In my scenario access token itself is used to get new one

            const accountsApiConfig = {
                method: 'post',
                baseURL: getBaseUrl(ACCOUNT),
                url: '/auth/token/refresh',
            };

            try {
                const accountResponse = await http(accountsApiConfig);
                if (accountResponse.status >= 200 && accountResponse.status < 400) {
                    await  localStorage.setItem('token', accountResponse.data.access_token);
                }

            } catch (error) {
                // logout();
                throw new Error(error);
            }
        } catch (e) {

        } finally {
            //Finally block is executed even if try block has return statements
            if(decodedToken !== null){
                //That's why I use it to schedule next refresh try
                this._refreshTimer = setTimeout(this._maybeRefresh.bind(this), 2000);
            }
        }
    }
}

export default RefreshTokenProvider

