export const GET_EKYC_DOCUMENT_STATUS = 'GET_EKYC_DOCUMENT_STATUS';
export const GET_EKYC_DOCUMENT_STATUS_SUCCESS = 'GET_EKYC_DOCUMENT_STATUS_SUCCESS';
export const GET_EKYC_DOCUMENT_STATUS_FAILURE = 'GET_EKYC_DOCUMENT_STATUS_FAILURE';
export const GET_EKYC_DOCUMENT_STATUS_RESET = 'GET_EKYC_DOCUMENT_STATUS_RESET';
export const SET_EKYC_DOCUMENT_NEW_IDENTIFICATION = 'SET_EKYC_DOCUMENT_NEW_IDENTIFICATION';
export const SET_EKYC_DOCUMENT_NEW_VISA = 'SET_EKYC_DOCUMENT_NEW_VISA';
export const USER_ATTACHMENT_UPLOAD = 'USER_ATTACHMENT_UPLOAD';
export const USER_ATTACHMENT_UPLOAD_SUCCESS = 'USER_ATTACHMENT_UPLOAD_SUCCESS';
export const USER_ATTACHMENT_UPLOAD_FAILURE = 'USER_ATTACHMENT_UPLOAD_FAILURE';
export const USER_ATTACHMENT_UPLOAD_RESET = 'USER_ATTACHMENT_UPLOAD_RESET';


