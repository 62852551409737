import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading.js";

const LoadableComponent = loadable(() => import("./EditBeneficiary"), {
  fallback: <Loading />
});

const LoadableEditBeneficiary = () => (
  <LoadableComponent />
)

export default LoadableEditBeneficiary;
