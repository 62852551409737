import axios from 'axios';
import queryString from 'query-string';
import RefreshTokenProvider from "./utils/RefreshTokenProvider";
import {getBaseUrl, REMITFX} from "./baseUrl";

const http = axios.create({
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});
http.defaults.validateStatus = function () {
    return true;
};

http.interceptors.request.use(
    async (config) => {
        let tokenRemitFX = localStorage.getItem('token_remitfx');
        const parsedString = queryString.parse(window.location.search);
        const {redirectUrl: redirect, token: t, region: r} = parsedString;

        if (redirect) {
            localStorage.setItem('redirectUrl', redirect);
        }

        if (r) {
            localStorage.setItem('region', r);
        }

        localStorage.setItem('intended-route', window.location.pathname);

        if (t) {
            tokenRemitFX = t;
            localStorage.setItem('token_remitfx', t);
        } else {
            localStorage.removeItem('token_remitfx');
            tokenRemitFX = null;
            window.location.replace(`${getBaseUrl(REMITFX)}`)
        }

        if (tokenRemitFX) config.headers.Authorization = `Bearer ${tokenRemitFX}`;
        return config;
    },
    (error) => Promise.reject(error),
);

http.interceptors.response.use((response) => {
    if (response.status === 401) {
        RefreshTokenProvider.instance.stop();
        localStorage.removeItem('token_remitfx');
        window.location.replace(`${getBaseUrl(REMITFX)}`)
    }

    if (response.status >= 200 && response.status < 300 && response.data.is_successful) {
        const redirectUrl = localStorage.getItem('redirectUrl');
        localStorage.removeItem('redirectUrl');

        window.location.replace(redirectUrl);
    }

    return response;
}, (error) => {
    return Promise.reject(error);
});

export default http;
