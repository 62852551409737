import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router";
import {Button, Grid} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";
import {GeneralInput, SelectInput, DateInput, AutoCompleteInput, MobileNumberInput} from "../../../Component/FlexForm";
import {
  SUBMIT_EDIT_COMPLETE_PROFILE_FORM,
  SUBMIT_FLEX_FORM,
  UPDATE_FILLED_DATA,
  VERIFY_FLEX_FORM
} from "../../../Assets/Constant/CompleteProfileForm";


const useStyles = makeStyles({
  border: {
    marginTop: 16,
    marginBottom: 16,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontFamily: "'Lato', sans-serif",
    fontSize: 16,
    marginBottom: 16,
  },
});

const PersonalAccount = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector(state => state.flexForm.data);
  const filledData = useSelector(state => state.flexForm.filledData);
  const loading = useSelector(state => state.flexForm.loading)

  const { id } = useParams();

  const onChangeData = (name, input, entityType, type) => {
    if (input) {
      dispatch({
        type: UPDATE_FILLED_DATA,
        data: {
          [name]: input,
          __target: null,
        }
      });
    }
  }

  const onChangeSelectData = (name, input) => {
    dispatch({
      type: UPDATE_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        __target: name,
      }
    })
  }

  const onSelectAddress = (name, input, entityType, placeId) => {
    dispatch({
      type: UPDATE_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        [placeId]: input.placeID,
        __target: name,
      }
    })
  }

  const onSearchAddress = (name, input, entityType, placeId) => {
    dispatch({
      type: UPDATE_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        [placeId]: '',
        __target: name,
      }
    })
  }

  useEffect(() => {
    if (filledData.__target) {
      dispatch({
        type: VERIFY_FLEX_FORM,
        data: filledData,
        entity_type: props.entityType
      })
    }
  }, [dispatch, filledData, props.entityType])

  const onSubmit = () => {
    if(props.type && props.type === 'edit') {
      dispatch({
        type: SUBMIT_EDIT_COMPLETE_PROFILE_FORM,
        data: filledData,
        entity_type: props.entityType,
        id,
      })
    } else {
      dispatch({
        type: SUBMIT_FLEX_FORM,
        data: filledData,
        entity_type: props.entityType,
      })
    }
  }

  const getForm = (item) => {
    switch (item.type) {
      case "select":
        return <SelectInput data={item} key={item.name} onChange={onChangeSelectData}/>;
      case "text":
        return <GeneralInput data={item} key={item.name} onChange={onChangeData}/>;
      case "autocomplete":
        return <AutoCompleteInput data={item} key={item.name} onChange={onSelectAddress} onSearch={onSearchAddress} />
      case "date":
        return <DateInput data={item} key={item.name} onChange={onChangeData}/>
      case "phone-number":
        return <MobileNumberInput data={item} key={item.name} onChange={onChangeData}/>
      default:
        return null;
    }
  }

  const renderUserDetail = () => {
    let form = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group !== 'User Details') return null;
        if (item.entity_type === props.entityType || item.entity_type === 'ALL') {
          return getForm(item)
        }
        return null;
      })
    }
    return form;
  }

  const renderResidentialAddress = () => {
    let form = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (!item.field_group?.includes('Residential Address in')) return null;
        if (item.entity_type === props.entityType || item.entity_type === 'ALL') {
          return getForm(item)
        }
        return null;
      })
    }
    return form;
  }

  return (
    <div>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item md={8} xs={12}>
          <div>
            <p className={classes.sectionHeader}>User Details</p>
            <hr className={classes.border}/>
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {renderUserDetail()}
            </Grid>
          </div>
          <br/>
          <br/>
          <div>
            <p className={classes.sectionHeader}>Residential Address</p>
            <hr className={classes.border}/>
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {renderResidentialAddress()}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <br/>
      <br/>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item md={6} xs={12}>
          <Button
            className={classes.saveButton}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={loading}
            onClick={(e) => onSubmit()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <br/>
    </div>
  );
};

export default PersonalAccount;
