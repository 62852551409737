import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Register from './Pages/Register/RegisterLoadable'
import SecurityImage from "./Pages/SecurityImage/SecurityImageLoadable";
import Home from "./Pages/Home/HomeLoadable";
import CompleteProfile from "./Pages/CompleteProfile/AddCompleteProfileLoadable";
import EditCompleteProfile from "./Pages/CompleteProfile/EditCompleteProfile";
import AddNewBeneficiary from "./Pages/Beneficiary/AddNewBeneficiary";
import EditBeneficiary from "./Pages/Beneficiary/EditBeneficiaryLoadable";
import AuthCallback from "./Pages/AuthCallback";
import UserBank from "./Pages/UserBank/UserBankLoadable";
import UserBankEdit from "./Pages/UserBank/UserBankEditLoadable";
import EditMissingBeneficiary from "./Pages/Beneficiary/EditMissingBeneficiaryLoadable";
import EditMissingSenderInfo from "./Pages/Beneficiary/EditMissingSenderInfoLoadable";
import DocumentUpload from "./Pages/DocumentUpload/DocumentUploadLoadable";
import RemitFXTransfer from "./Pages/RemitFXTransfer/AddRemitFXTransferLoadable";

export default function routerIndex() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/security-image">
            <SecurityImage />
          </Route>
          <Route path="/sign-up">
            <Register />
          </Route>
          <Route path="/complete-profile/edit/:id">
            <EditCompleteProfile />
          </Route>
          <Route path="/complete-profile">
            <CompleteProfile />
          </Route>
          <Route path="/beneficiary/edit/:payoutMethod/:complianceAccountId">
            <EditBeneficiary />
          </Route>
          <Route path="/beneficiary/create">
            <AddNewBeneficiary />
          </Route>
          <Route path="/missing-beneficiary-info/edit/:payoutMethod/:complianceAccountId">
            <EditMissingBeneficiary />
          </Route>
          <Route path="/missing-user-info/edit/:source/:sourceUniqueId">
            <EditMissingSenderInfo />
          </Route>
          <Route path="/user/upload-expired-document">
            <DocumentUpload />
          </Route>
          <Route path="/user-bank/edit/:id">
            <UserBankEdit />
          </Route>
          <Route path="/user-bank/create">
            <UserBank />
          </Route>
          <Route path="/sso-callback">
            <AuthCallback />
          </Route>
          <Route path="/transfers/form">
            <RemitFXTransfer />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
