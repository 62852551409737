import { takeLatest, put, call } from 'redux-saga/effects';

// Decrease Counter Async
function* getRegion() {
  try {
    // TODO: Remove hardcode
    const res = yield call(fetch, 'https://auth.beta.moneymatch.zone/api/v2/available-regions');
    const data = yield call([res, 'json']);
    yield put({
      type: 'GET_REGION_SUCCESS',
      data,
    });
  } catch (error) {
    yield put({
      type: 'GET_REGION_ERROR',
      error,
    });
  }
}

export function* watchRegion() {
  yield takeLatest('GET_REGION', getRegion);
}
