export const GET_CREATE_BANK_FORM = 'GET_CREATE_BANK_FORM';
export const GET_CREATE_BANK_FORM_SUCCESS = 'GET_CREATE_BANK_FORM_SUCCESS';
export const GET_CREATE_BANK_FORM_FAIL = 'GET_CREATE_BANK_FORM_FAIL';

export const VERIFY_NEW_BANK_FORM = 'VERIFY_NEW_BANK_FORM';
export const VERIFY_NEW_BANK_SUCCESS = 'VERIFY_NEW_BANK_SUCCESS';
export const VERIFY_NEW_BANK_FAIL = 'VERIFY_NEW_BANK_FAIL';

export const SUBMIT_NEW_BANK_FORM = 'SUBMIT_NEW_BANK_FORM';
export const SUBMIT_NEW_BANK_FORM_SUCCESS = 'SUBMIT_NEW_BANK_FORM_SUCCESS';
export const SUBMIT_NEW_BANK_FORM_FAIL = 'SUBMIT_NEW_BANK_FORM_FAIL';


export const GET_EDIT_BANK_FORM = 'GET_EDIT_BANK_FORM';
export const GET_EDIT_BANK_FORM_SUCCESS = 'GET_EDIT_BANK_FORM_SUCCESS';
export const GET_EDIT_BANK_FORM_FAIL = 'GET_EDIT_BANK_FORM_FAIL';

export const SUBMIT_EDIT_BANK_FORM = 'SUBMIT_EDIT_BANK_FORM';
export const SUBMIT_EDIT_BANK_FORM_SUCCESS = 'SUBMIT_EDIT_BANK_FORM_SUCCESS';
export const SUBMIT_EDIT_BANK_FORM_FAIL = 'SUBMIT_EDIT_BANK_FORM_FAIL';

export const UPDATE_OWN_BANK_FORM_FILLED_DATA = 'UPDATE_OWN_BANK_FORM_FILLED_DATA';
export const RESET_OWN_BANK_FORM_FILLED_DATA = 'RESET_OWN_BANK_FORM_FILLED_DATA';
