import { all, fork } from 'redux-saga/effects';

import { watchRegion } from './region';
import { watchFlexForm } from "./completeProfileForm";
import { watchBeneficiaryForm } from "./beneficiaryForm";
import { watchAddOwnBankForm } from "./bankForm";
import { watchMissingSenderInfoForm } from "./missingSenderInfoForm";
import { watchEkycDocumentsStatus } from "./ekycDocumentsStatus";
import { watchRemitFXTransferForm } from "./remitFXTransferForm";

export function* rootSaga() {
  yield all([
    fork(watchRegion),
    fork(watchFlexForm),
    fork(watchBeneficiaryForm),
    fork(watchAddOwnBankForm),
    fork(watchMissingSenderInfoForm),
    fork(watchEkycDocumentsStatus),
    fork(watchRemitFXTransferForm),
  ]);
}
