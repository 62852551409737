import {
  GET_CREATE_BANK_FORM,
  GET_CREATE_BANK_FORM_SUCCESS,
  GET_CREATE_BANK_FORM_FAIL,
  VERIFY_NEW_BANK_FORM,
  VERIFY_NEW_BANK_SUCCESS,
  VERIFY_NEW_BANK_FAIL,
  SUBMIT_NEW_BANK_FORM,
  SUBMIT_NEW_BANK_FORM_SUCCESS,
  SUBMIT_NEW_BANK_FORM_FAIL,
  GET_EDIT_BANK_FORM,
  UPDATE_OWN_BANK_FORM_FILLED_DATA
} from "../Assets/Constant/BankForm";
import {REMOVE_FLEX_FORM_ERROR} from "../Assets/Constant/CompleteProfileForm";

const initialState = {
  loading: false,
  data: [],
  error: null,
  bankFormError: null,
};

const bankFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OWN_BANK_FORM_FILLED_DATA:
      return {
        ...state,
        filledData: {
          ...state.filledData,
          ...action.data,
        },
      };
    case GET_CREATE_BANK_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_EDIT_BANK_FORM:
      return {
        ...state,
        loading: true,
      }
    case GET_CREATE_BANK_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.fields,
      };
    case GET_CREATE_BANK_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case REMOVE_FLEX_FORM_ERROR:
      state.data = state.data.map((item) => {
        if (item.name === action.data) {
          item.error = null;
          item.messages = [];
        }
        return item;
      })
      return state;
    case VERIFY_NEW_BANK_FORM:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_NEW_BANK_SUCCESS:
      return {
        ...state,
        data: state.data.map(obj => action.data.changes.find(o => o.name === obj.name) || obj),
        loading: false,
      };
    case VERIFY_NEW_BANK_FAIL:
      const newData = state.data.map(obj => action.data.changes.find(o => o.name === obj.name) || obj);
      const newDataWithErrors = newData.map(obj => {
        let result = action.errors.filter(id => obj.name === id.name);
        if (result.length > 0) {
          return {
            ...obj,
            messages: result[0].messages,
            error: true
          }
        }
        return obj;
      })
      return {
        ...state,
        data: newDataWithErrors,
        loading: false,
      };
    case SUBMIT_NEW_BANK_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_NEW_BANK_FORM_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_NEW_BANK_FORM_FAIL:
      return {
        ...state,
        data: state.data.map(obj => {
          let result = action.errors.filter(id => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true
            }
          }
          return obj;
        }),
        loading: false,
      };
    default:
      return state;
  }
};

export default bankFormReducer;
