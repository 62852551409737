import React from 'react';
import { Grid, Box, Link, Typography } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    footer: {
        backgroundColor: "#333",
        padding: 20,
    },
    col: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 20,
    },
    colHeader: {
        fontSize: 16,
        fontFamily: "inherit",
    },
    p: {
        width: '100%',
        marginBottom: 2,
        textAlign: 'center',
        color: 'white',
        fontSize: 10,
        margin: 10,
    },
    img: {
        maxHeight: 65,
        paddingLeft: 4,
        paddingRight: 4,
        marginBottom: 8
    },
    icon: {
        height: 35,
        paddingRight: 4,
    },
    link: {
        marginTop: 5,
        fontSize: 14,
        color: 'white',
    },
    socialMediaLink: {
        marginRight: 30,
    },
});

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Grid container direction="row" wrap="wrap-reverse" spacing={0}>
                <Grid container direction="row" justify="center">
                    <Grid className={classes.col} item sm={2} xs={12}>
                        <Box display="flex" flexWrap="wrap" flexDirection="column" justifyContent="around" alignItems="center">
                            <img className={classes.img} src={require('../Assets/Images/MMlogo2.png')} alt="MoneyMatch company logo"/>
                            <div>
                                <p className={classes.p}><strong>Operating Hours</strong>:<br/>9.00 AM - 5.30 PM<br/>(Weekdays only, except Public Holidays)</p>
                                <p className={classes.p}><strong>Customer Support</strong>:<br/>customer.support@moneymatch.co</p>
                                <p className={classes.p}><strong>MoneyMatch HQ</strong>:<br/>Unit 3.03, Menara LGB, 1 Jalan Wan Kadir,<br/>Taman Tun Dr Ismail, Kuala Lumpur, 60000, Malaysia.</p>
                            </div>
                            <Box display="flex" justifyContent="around" flexDirection="row" alignItems="center">
                                <Link href="https://play.google.com/store/apps/details?id=co.moneymatch.app" target="_blank" rel="noopener"><img className={classes.icon} src={require('../Assets/Images/PlayStoreButton.svg')} alt="asd"/></Link>
                                <Link href="https://itunes.apple.com/my/app/moneymatch/id1269958558?mt=8" target="_blank" rel="noopener"><img className={classes.icon} src={require('../Assets/Images/AppStoreButton.svg')} alt="asd"/></Link>
                            </Box>
                            <Box display="flex" justifyContent="around" flexDirection="row" alignItems="center">
                                <Link className={classes.socialMediaLink} target="_blank" rel="noopener" href="https://www.linkedin.com/company/moneymatch-app"><LinkedInIcon fontSize="small"/></Link>
                                <Link className={classes.socialMediaLink} target="_blank" rel="noopener" href="https://www.facebook.com/moneymatchapp/"><FacebookIcon fontSize="small"/></Link>
                                <Link className={classes.socialMediaLink} target="_blank" rel="noopener" href="https://twitter.com/moneymatchapp?lang=en "><TwitterIcon fontSize="small"/></Link>
                                <Link className={classes.socialMediaLink} target="_blank" rel="noopener" href="https://www.instagram.com/moneymatchapp/"><InstagramIcon fontSize="small"/></Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid className={classes.col} item sm={2} xs={12}>
                        <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center">
                            <Typography className={classes.colHeader} variant="h6" color="primary">Company</Typography>
                            <Link className={classes.link} href="/about-us">About Us</Link>
                            <Link className={classes.link} href="/business">Business</Link>
                            <Link className={classes.link} href="/careers">Careers</Link>
                        </Box>
                    </Grid>
                    <Grid className={classes.col} item sm={2} xs={12}>
                        <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center">
                            <Typography className={classes.colHeader} variant="h6" color="primary">Support</Typography>
                            <Link className={classes.link} href="/about-us">Transfer Fees</Link>
                            <Link className={classes.link} href="/business">Exchange Rates</Link>
                            <Link className={classes.link} href="/careers">Coverage</Link>
                        </Box>
                    </Grid>
                    <Grid className={classes.col} item sm={2} xs={12}>
                        <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center">
                            <Typography className={classes.colHeader} variant="h6" color="primary">Legal</Typography>
                            <Link className={classes.link} href="/about-us">Terms of Service</Link>
                            <Link className={classes.link} href="/business">Private Policy</Link>
                        {/*  <div id="DigiCertClickID_DIYpKmgh" data-language="en"></div> */}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid className={classes.col} item sm={12} xs={12}>
                    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center">
                        <p className={classes.p}>
                            MoneyMatch Sdn. Bhd. is regulated by Central Bank <Link target="_blank" href="http://www.bnm.gov.my/microsite/fs/msb/agents/Microsite_Digital.pdf">(licensee providing digital services)</Link> under MY Licence Serial Number 00147 in Malaysia.
                            <br/>Perniagaan Perkhidmatan Wang Berlesen <br/>
                            Pengirim Wang
                        </p>
                        <p className={classes.p}>
                            MMB Transfer Sdn Bhd [Brunei] is an approved FinTech Sandbox participant under the Autoriti Monetari Brunei Darrusalam
                        </p>
                        <p className={classes.p}>
                            MoneyMatch (Australia) Pty Ltd is regulated by the Australian Securities and Investments Commission (ASIC), and registered with the Financial Intelligence Unit (AUSTRAC) as a remittance provider.<br/>Our ASIC licence number is 627734623, and our ABN is 49 627 734 623.
                        </p>
                        <p className={classes.p}>
                            ALL RIGHTS RESERVED 2020 MONEYMATCH SDN.BHD. (201501008276)
                        </p>
                    </Box>
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;
