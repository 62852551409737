import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";
import {
  GeneralInput,
  SelectInput,
  DateInput,
  AutoCompleteInput,
  MobileNumberInput,
  BankInput,
  TACInput,
  TickBox
} from "../../../Component/FlexForm";
import {
  SUBMIT_BENEFICIARY_FORM,
  VERIFY_BENEFICIARY_FORM,
  UPDATE_BENEFICIARY_FILLED_DATA, SUBMIT_EDIT_BENEFICIARY_FORM,
} from "../../../Assets/Constant/BeneficiaryForm";
import {UPDATE_FILLED_DATA} from "../../../Assets/Constant/CompleteProfileForm";
import {useParams} from "react-router";

const useStyles = makeStyles({
  border: {
    marginTop: 16,
    marginBottom: 16,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontFamily: "'Lato', sans-serif",
    fontSize: 16,
    marginBottom: 16,
  },
  modal : {
    position: "absolute",
    top: '50%',
    left: '50%',
    // width: '300px',
    // height: '300px',
    // right: 'auto',
    // bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    // -webkit-box-shadow: '1px 1px 5px 0px rgba(184,178,184,1)',
    // -moz-box-shadow: 1px 1px 5px 0px rgba(184,178,184,1),
    boxShadow: '1px 1px 5px 0px rgba(184,178,184,1)',
    borderColor: "white",
    padding: 20,
    borderRadius: 10,
  }
});

const BusinessBeneficiary = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector(state => state.beneficiaryForm.data);
  const loading = useSelector(state => state.beneficiaryForm.loading);
  const additionalFieldData = useSelector(state => state.beneficiaryForm.additionalFieldData);
  const filledData = useSelector(state => state.beneficiaryForm.filledData);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [additionalField, setAdditionalField] = useState([])

  const { payoutMethod, complianceAccountId } = useParams();

  useEffect(() => {
    setAdditionalField(additionalFieldData.filter(item => item.visible === true))
  }, [additionalFieldData])

  const closeModal = () => {
    setModalIsOpen(false)
    if (filledData.__target) {
      setTimeout(() => {
        dispatch({
          type: UPDATE_BENEFICIARY_FILLED_DATA,
          data: {
            __target: 'bank_country_iso'
          }
        })
      }, 500)
    }
  }

  const onChangeData = (name, input, entityType, type) => {
    if (input) {
      dispatch({
        type: UPDATE_BENEFICIARY_FILLED_DATA,
        data: {
          [name]: input,
          __target: name
        }
      });
    }
  }

  const onChangeSelectData = (name, input) => {
    dispatch({
      type: UPDATE_BENEFICIARY_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        __target: name,
      }
    })
  }

  const onSelectAddress = (name, input, entityType, placeId) => {
    dispatch({
      type: UPDATE_BENEFICIARY_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        [placeId]: input.placeID,
        __target: name,
      }
    })
  }

  const onSearchAddress = (name, input, entityType, placeId) => {
    console.log(name,input,entityType, placeId)
    dispatch({
      type: UPDATE_BENEFICIARY_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        [placeId]: '',
        __target: name,
      }
    })
  }

  useEffect(() => {
    if (filledData.__target) {
      dispatch({
        type: VERIFY_BENEFICIARY_FORM,
        data: filledData,
        entity_type: props.entityType
      })
    }
  }, [filledData])

  const onSubmit = () => {
    if(props.update) {
      dispatch({
        type: SUBMIT_EDIT_BENEFICIARY_FORM,
        filledData,
        entity_type: props.entityType,
        payoutMethod,
        complianceAccountId,
      })
    } else {
      dispatch({
        type: SUBMIT_BENEFICIARY_FORM,
        data: filledData,
        entity_type: props.entityType
      })
    }
  }

  const onChangeBankName = (name, input) => {
    dispatch({
      type: UPDATE_BENEFICIARY_FILLED_DATA,
      data: {
        [name]: input ? input.value : '',
        __target: name
      }
    })
  }

  const onChangeTickBox = (name, input) => {
    dispatch({
      type: UPDATE_BENEFICIARY_FILLED_DATA,
      data: {
        [name]: input,
        __target: name
      }
    });
  }

  const getForm = (item) => {
    switch (item.type) {
      case "select":
        return <SelectInput data={item} key={item.name} onChange={onChangeSelectData} />;
      case "text":
        return <GeneralInput data={item} key={item.name} onChange={onChangeData} />;
      case "autocomplete":
        return <AutoCompleteInput data={item} key={item.name} onChange={onSelectAddress} onSearch={onSearchAddress} />
      case "date":
        return <DateInput data={item} key={item.name} onChange={onChangeData} />;
      case "phone-number":
        return <MobileNumberInput data={item} key={item.name} onChange={onChangeData} />;
      case "bank":
        return <BankInput data={item} key={item.name} onChange={onChangeBankName} />;
      case "boolean":
        return <TickBox data={item} key={item.name} onChange={onChangeTickBox} />;
      case "sms-verification":
        return <TACInput data={item} key={item.name} onChange={onChangeData} />;
      default:
        return null;
    }
  }

  const renderBusinessDetails = () => {
    let form = null;
    let visibleFields = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group === "Business's Details" && item.visible === true) {
          return getForm(item);
        }
        return null;
      })

      return form.length > 0 ? (
          <div>
            {
              form.filter((item) => item != null).length > 0 ?
                (<>
                      <p className={classes.sectionHeader}>Business Details</p>
                      <hr className={classes.border}/>
                    </>
                ):(<></>)
            }
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {form}
            </Grid>
            <br></br>
           </div>
      ) : null;
    }

    return null
  }
  
  const renderBusinessAddress = () => {
    let form = null;
    let visibleFields = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group?.includes('Business Address in') && item.visible === true) {
          return getForm(item);
        }
        return null;
      })

      return form.length > 0 ? (
          <div>
            {
              form.filter((item) => item != null).length > 0 ?
                (<>
                      <p className={classes.sectionHeader}>Business Address</p>
                      <hr className={classes.border}/>
                    </>
                ):(<></>)
            }
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {form}
            </Grid>
            <br></br>
           </div>
      ) : null;
    }

    return null
  }

  const renderCountryDetails = () => {
    let form = null;
    let visibleFields = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group === "Country Details" && item.visible === true) {
          return getForm(item);
        }
        return null;
      })

      return form.length > 0 ? (
          <div>
            {
              form.filter((item) => item != null).length > 0 ?
                (<>
                      <p className={classes.sectionHeader}>Country Details</p>
                      <hr className={classes.border}/>
                    </>
                ):(<></>)
            }
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {form}
            </Grid>
            <br></br>
           </div>
      ) : null;
    }

    return null
  }

  const renderBankDetails = () => {
    let form = null;
    let visibleFields = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group === "Bank Details" && item.visible === true) {
          return getForm(item);
        }
        return null;
      })

      return form.length > 0 ? (
          <div>
            {
              form.filter((item) => item != null).length > 0 ?
                (<>
                      <p className={classes.sectionHeader}>Bank Details</p>
                      <hr className={classes.border}/>
                    </>
                ):(<></>)
            }
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {form}
            </Grid>
            <br></br>
           </div>
      ) : null;
    }

    return null
  }

  const renderAccountDetails = () => {
    let form = null;
    let visibleFields = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group === "Account Details" && item.visible === true) {
          return getForm(item);
        }
        return null;
      })

      return form.length > 0 ? (
          <div>
            {
              form.filter((item) => item != null).length > 0 ?
                (<>
                      <p className={classes.sectionHeader}>Account Details</p>
                      <hr className={classes.border}/>
                    </>
                ):(<></>)
            }
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {form}
            </Grid>
            <br></br>
          </div>
      ) : null;
    }

    return null
  }
  
  const renderOneTimeOTPVerfication = () => {
    let form = null;
    let visibleFields = null;
    if (formData.length !== 0) {
      form = formData.map((item) => {
        if (item.field_group === "One-Time Verification" && item.visible === true) {
          return getForm(item);
        }
        return null;
      })

      return form.length > 0 ? (
          <div>
            {
              form.filter((item) => item != null).length > 0 ?
                (<>
                      <p className={classes.sectionHeader}>One-Time Verification</p>
                      <hr className={classes.border}/>
                    </>
                ):(<></>)
            }
            <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
              {form}
            </Grid>
            <br></br>
          </div>
      ) : null;
    }

    return null
  }

  const renderAdditionalDetails = () => {
    let form = null;
    if (additionalField.length !== 0) {
      form = additionalField.map((item) => {
        if (item.field_group !== "Additional Fields") return null;
        if (item.entity_type === props.entityType || item.entity_type === 'ALL') {
          return getForm(item)
        }
        return null;
      })
    }
    return additionalField.length !== 0 ? (
      <div>
        <p className={classes.sectionHeader}>Additional Details</p>
        <hr className={classes.border}/>
        <Grid container direction="row" alignItems="flex-start" justify="flex-start" wrap="wrap" spacing={3}>
          {form}
        </Grid>
        <br></br>
      </div>
    ) : null;
  }

  return (
    <div>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item md={8} xs={12}>
          {renderCountryDetails()}
          {renderBankDetails()}
          {renderAccountDetails()}
          {renderAdditionalDetails()}
          {renderBusinessDetails()}
          {renderBusinessAddress()}
          {renderOneTimeOTPVerfication()}
        </Grid>

      </Grid>
      <br/>
      <br/>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item md={6} xs={12}>
          <Button
            className={classes.saveButton}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={(e) => onSubmit()}
            disabled={loading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <br/>
    </div>
  );
};

export default BusinessBeneficiary;
