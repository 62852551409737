import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_CREATE_BANK_FORM,
  GET_CREATE_BANK_FORM_SUCCESS,
  GET_CREATE_BANK_FORM_FAIL,
  VERIFY_NEW_BANK_FORM,
  VERIFY_NEW_BANK_SUCCESS,
  VERIFY_NEW_BANK_FAIL,
  SUBMIT_NEW_BANK_FORM,
  SUBMIT_NEW_BANK_FORM_SUCCESS,
  SUBMIT_NEW_BANK_FORM_FAIL,
  GET_EDIT_BANK_FORM,
  SUBMIT_EDIT_BANK_FORM,
  SUBMIT_EDIT_BANK_FORM_SUCCESS,
  SUBMIT_EDIT_BANK_FORM_FAIL,
} from "../Assets/Constant/BankForm";

import { getBaseUrl, COMPLIANCE } from '../Network/baseUrl';
import http from '../Network/http';

function* getBankForm() {
  try {
    const region = localStorage.getItem('region');
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/user-bank/create',
      params: {
        region,
      }
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    yield put({
      type: GET_CREATE_BANK_FORM_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: GET_CREATE_BANK_FORM_FAIL,
      error: e,
    });
  }
}

function* verifyBankFormInput(item) {
  try {
    const region = localStorage.getItem('region');
    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/user-bank/verify-input',
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
        region
      })
    };
    const res = yield call(http, apiConfig);
    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: VERIFY_NEW_BANK_SUCCESS,
        data,
        target: item.data.__target
      });
    } else {
      yield put({
        type: VERIFY_NEW_BANK_FAIL,
        data,
        target: item.data.__target,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* submitBankForm(item) {
  try {
    const region = localStorage.getItem('region');

    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: '/user-bank',
      params: {
        region
      },
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
      })
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_NEW_BANK_FORM_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: SUBMIT_NEW_BANK_FORM_FAIL,
        data,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

function* getEditBankForm(data) {
  const { id } = data;
  const region = localStorage.getItem('region');
  try {
    const apiConfig = {
      method: 'get',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/user-bank/${id}/edit`,
      params: {
        region,
      },
    };
    const res = yield call(http, apiConfig);
    const { data: { data } } = res;

    yield put({
      type: GET_CREATE_BANK_FORM_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: GET_CREATE_BANK_FORM_FAIL,
      error: e,
    });
  }
}

function* submitEditBankForm(item) {
  try {
    const region = localStorage.getItem('region');

    const apiConfig = {
      method: 'post',
      baseURL: getBaseUrl(COMPLIANCE),
      url: `/user-bank/${item.id}/update`,
      params: {
        region
      },
      data: JSON.stringify({
        ...item.data,
        entity_type: item.entity_type,
      })
    };
    const res = yield call(http, apiConfig);

    const { data: { data, errors } } = res;
    if (res.status === 200) {
      yield put({
        type: SUBMIT_NEW_BANK_FORM_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: SUBMIT_NEW_BANK_FORM_FAIL,
        data,
        errors
      });
    }

  } catch (e) {
    console.log('error', e);
  }
}

export function* watchAddOwnBankForm() {
  yield takeLatest(GET_CREATE_BANK_FORM, getBankForm);
  yield takeLatest(VERIFY_NEW_BANK_FORM, verifyBankFormInput);
  yield takeLatest(SUBMIT_NEW_BANK_FORM, submitBankForm);
  yield takeLatest(GET_EDIT_BANK_FORM, getEditBankForm);
  yield takeLatest(SUBMIT_EDIT_BANK_FORM, submitEditBankForm);
}
