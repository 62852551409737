import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'

const ToggleButtonGroup = ({ style, changeView, defaultValue, children }) => {
  const classes = useStyles()
  const [selectedEntityType, setSelectedEntityType] = useState(defaultValue)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  const handleOnClick = (entityType) => {
    setSelectedEntityType(entityType)
    changeView(entityType)
  }

  const renderDesktopView = () => {
    return (
      <div className={classes.container} style={style}>
        {renderButtons()}
      </div>
    )
  }

  const renderMobileView = () => {
    return (
      <div className={classes.mobileContainer} style={style}>
        {renderButtons()}
      </div>
    )
  }

  const renderButtons = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        classes: classes,
        selectedEntityType: selectedEntityType,
        handleOnClick: handleOnClick,
        isDesktopView: screenWidth > 960,
      })
    })
  }

  return screenWidth > 960 ? renderDesktopView() : renderMobileView()
}

ToggleButtonGroup.Button = ({
  value,
  title,
  icon,
  children,
  classes,
  selectedEntityType,
  handleOnClick,
  isDesktopView,
}) => {
  return isDesktopView ? (
    <div
      className={
        selectedEntityType === value ? classes.buttonOnClick : classes.button
      }
      onClick={() => handleOnClick(value)}
    >
      <div
        className={
          selectedEntityType === value
            ? classes.iconContainerOnClick
            : classes.iconContainer
        }
        style={{
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {React.cloneElement(icon, {
          style: {
            fontSize: '48px',
          },
        })}
      </div>
      <p className={classes.buttonTitle}>{title}</p>
      {children}
      {selectedEntityType === value && (
        <div className={classes.buttonOnClickTick}>
          <DoneIcon fontSize='large' style={{ color: 'white' }} />
        </div>
      )}
    </div>
  ) : (
    <div
      className={
        selectedEntityType === value
          ? classes.mobileButtonOnClick
          : classes.mobileButton
      }
      onClick={() => handleOnClick(value)}
    >
      <div
        className={
          selectedEntityType === value
            ? classes.mobileIconContainerOnClick
            : classes.mobileIconContainer
        }
        style={{
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {React.cloneElement(icon, {
          style: {
            fontSize: '24px',
          },
        })}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 16,
          alignItems: 'center',
          flex: 1,
        }}
      >
        <div style={{ flex: 1 }}>
          <p className={classes.mobileButtonTitle}>{title}</p>
          {children}
        </div>
        <div>
          {selectedEntityType === value ? (
            <div className={classes.mobileButtonOnClickTick}>
              <DoneIcon fontSize='small' style={{ color: 'white' }} />
            </div>
          ) : (
            <div style={{ width: '32px' }}></div>
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '24px',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  button: {
    position: 'relative',
    border: '2px solid #EEE',
    flex: 1,
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
  },
  mobileButton: {
    border: '2px solid #EEE',
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
    gap: '16px',
  },
  buttonOnClick: {
    position: 'relative',
    border: '2px solid #F7E21E',
    flex: 1,
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
  },
  mobileButtonOnClick: {
    position: 'relative',
    border: '2px solid #F7E21E',
    flex: 1,
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
    gap: '16px',
  },
  buttonOnClickTick: {
    position: 'absolute',
    right: '-24px',
    bottom: '-18px',
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    backgroundColor: '#F7E21E',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileButtonOnClickTick: {
    height: '34px',
    width: '34px',
    borderRadius: '50%',
    backgroundColor: '#F7E21E',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: '48px',
    width: '48px',
  },
  mobileIcon: {
    height: '28px',
    width: '28px',
  },
  iconContainer: {
    height: '80px',
    width: '80px',
    backgroundColor: '#EEE',
  },
  iconContainerOnClick: {
    height: '80px',
    width: '80px',
    backgroundColor: 'rgba(247, 226, 30, 0.16)',
  },
  mobileIconContainer: {
    height: '40px',
    width: '40px',
    backgroundColor: '#EEE',
    borderRadius: '50%',
  },
  mobileIconContainerOnClick: {
    height: '40px',
    width: '40px',
    backgroundColor: 'rgba(247, 226, 30, 0.16)',
  },
  buttonTitle: {
    marginBottom: 0,
    fontSize: '24px',
    fontWeight: 700,
  },
  mobileButtonTitle: {
    marginBottom: 0,
    fontSize: '16px',
    fontWeight: 700,
  },
})

export default ToggleButtonGroup
