export const GET_EDIT_MISSING_SENDER_INFO_FORM = 'GET_EDIT_MISSING_SENDER_INFO_FORM';
export const GET_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS = 'GET_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS';
export const GET_EDIT_MISSING_SENDER_INFO_FORM_FAIL = 'GET_EDIT_MISSING_SENDER_INFO_FORM_FAIL';

export const VERIFY_EDIT_MISSING_SENDER_INFO_FORM = 'VERIFY_EDIT_MISSING_SENDER_INFO_FORM';
export const VERIFY_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS = 'VERIFY_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS';
export const VERIFY_EDIT_MISSING_SENDER_INFO_FORM_FAIL = 'VERIFY_EDIT_MISSING_SENDER_INFO_FORM_FAIL';

export const SUBMIT_EDIT_MISSING_SENDER_INFO_FORM = 'SUBMIT_EDIT_MISSING_SENDER_INFO_FORM';
export const SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS = 'SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS';
export const SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_FAIL = 'SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_FAIL';

export const UPDATE_MISSING_SENDER_INFO_FILLED_DATA = 'UPDATE_MISSING_SENDER_INFO_FILLED_DATA';
export const RESET_MISSING_SENDER_INFO_FILLED_DATA = 'RESET_MISSING_SENDER_INFO_FILLED_DATA';
