import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Route from './route';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import tagManager from 'react-gtm-module';
import env from './env.json';

import theme from "./theme";
import { Provider } from 'react-redux'
import rootReducer from './Reducers/store';
import { rootSaga } from './Sagas';

import Header from './Pages/Header';
import RefreshTokenProvider from "./Network/utils/RefreshTokenProvider";

const tagManagerParams = {
  gtmId: process.env.NODE_ENV === "development" ? env.GTM_ID : process.env.REACT_APP_GTM_ID
}

tagManager.initialize(tagManagerParams);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      sagaMiddleware,
      // createLogger()
    )
  )
);

sagaMiddleware.run(rootSaga);

class Doc extends React.Component{
  componentDidMount(){
    document.title="MoneyMatch";
    RefreshTokenProvider.instance.start();

  }
  render(){
    return(null)
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Doc />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Header />
        <Route />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
