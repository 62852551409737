import env from '../env.json';

export const MMT = 'MMT';
export const AUTH = 'AUTH';
export const KYC = 'KYC';
export const MMT_WEB = 'MMT_WEB';
export const COMPLIANCE = 'COMPLIANCE';
export const ACCOUNT = 'ACCOUNT';
export const SPA = 'SPA';
export const REMITFX = 'REMITFX';

export function getBaseUrl(code) {
  switch (code.toUpperCase()) {
    case KYC:
      return process.env.REACT_APP_URL_KYC;
    case AUTH:
      return process.env.REACT_APP_URL_AUTH;
    case MMT:
      return process.env.REACT_APP_URL_MMT;
    case MMT_WEB:
      return process.env.REACT_APP_URL_MMT_WEB;
    case COMPLIANCE:
      return process.env.REACT_APP_URL_COMPLIANCE;
    case ACCOUNT:
      return process.env.REACT_APP_URL_ACCOUNT;
    case REMITFX:
      return `${getRemitFXURL().toUpperCase()}`;
    case SPA:
      return process.env.REACT_APP_URL_SPA;
    default:
      return code;
  }
};

export function getRemitFXURL() {
  let region = localStorage.getItem('region') ?? 'MY';
  let reactValue = `REACT_APP_URL_REMITFX_${region}`;
  return process.env[reactValue];
}